import React, { useEffect, useState, useCallback, useRef } from "react"
import PricingCards from "../Components/PricingCards";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Spinner from "../Components/stream/overlays/spinner";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserDetailsQuery } from "../app/services/auth/authService";
import { setCredentials } from "../features/auth/userSlice";
import { logout, refreshTokens, setUserPlan } from "../features/auth/authSlice";
import { toast } from "react-toastify";
import { useRefreshUserTokenQuery } from "../app/services/auth/refreshService";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useRazorpay from "react-razorpay";
import AntLogo from '../images/Ant-Logo.webp';
import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { statesOfIndia } from "../assets/stateCity";
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';


export default function Pricing() {
    const [Razorpay] = useRazorpay();

    const plans = [
        {
            "id": "65f556c7c341739a9e9eabf2",
            "planName": "Premium",
            "planType": "antplay",
            "planTerm": 30,
            "planPrice": "599",
            "planHourLimit": 25,
            "razorpayPlanId": "plan_Nvh17yjSVXtVUq",
            "status": true,
            "planResources": {
                "gpu": "GDDR5, 6GB vGPU",
                "cpu": "8 vCPU",
                "ram": "12 GB RAM",
                "ssd": "250 GB SSD"
            },
            "totalCount": 300,
            "resolution": "1080p",
            "order": "1",
            "createdAt": "2024-03-16T08:22:31.758Z",
            "updatedAt": "2024-03-16T08:22:31.758Z"
        },
        {
            "id": "65f5560ec341739a9e9eabd3",
            "planName": "Ultimate",
            "planType": "antplay",
            "planTerm": 30,
            "planPrice": "899",
            "planHourLimit": 50,
            "razorpayPlanId": "plan_Nvh1rcCrmAr3nt",
            "status": true,
            "planResources": {
                "gpu": "GDDR5, 6GB vGPU",
                "cpu": "8 vCPU",
                "ram": "12 GB RAM",
                "ssd": "250 GB SSD"
            },
            "totalCount": 300,
            "resolution": "4K",
            "order": "2",
            "createdAt": "2024-03-16T08:19:26.320Z",
            "updatedAt": "2024-03-16T08:19:26.320Z"
        },
        {
            "id": "65f55670c341739a9e9eabea",
            "planName": "TopUp",
            "planType": "antplay",
            "planPrice": "40",
            "planHourLimit": 1,
            "status": true,
            "planResources": {
                "gpu": "GDDR5, 6GB vGPU",
                "cpu": "8 vCPU",
                "ram": "12 GB RAM",
                "ssd": "250 GB SSD"
            },
            "order": "3",
            "createdAt": "2024-03-16T08:21:04.256Z",
            "updatedAt": "2024-03-16T08:21:04.256Z"
        }
    ]

    const AdvancedPlans = [
        {
            planName: "Premium",
            planCode: "Premium",
            planTerm: 30,
            planPrice: "599",
            planHourLimit: 25,
            status: true,
            resolution: "1080p",
            order: "1",
        },
        {
            planName: "Ultimate",
            planCode: "Ultimate",
            planTerm: 30,
            planPrice: "899",
            planHourLimit: 50,
            status: true,
            resolution: "4K",
            order: "2",
        }, {
            planName: "TopUp",
            planCode: "TopUp",
            planPrice: "40",
            planHourLimit: 1,
            status: true,
            resolution: "",
        },
    ]

    const IntroPlans = [
        {
            planName: "Premium",
            planCode: "GTXPremium",
            planTerm: 30,
            planPrice: "199",
            planHourLimit: 35,
            status: true,
            resolution: "720p",
            order: "1",
        },
        {
            planName: "Ultimate",
            planCode: "GTXUltimate",
            planTerm: 30,
            planPrice: "499",
            planHourLimit: 100,
            status: true,
            resolution: "1080p",
            order: "2",
        }, {
            planName: "TopUp",
            planCode: "TopUp",
            planType: "antplay",
            planPrice: "40",
            planHourLimit: 1,
            status: true,
            order: "3",
            resolution: "",
        },
    ]

    const style = {
        position: 'absolute',
        textAlign: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "90%",
        ["@media (min-width:992px)"]: {
            width: "40%",
        },
        // width: 600,
        color: "#fff",
        bgcolor: '#212121',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        '&:focus-visible': {
            outline: 'none',
        }
    };

    const loaderModalStyle = {
        position: 'absolute',
        textAlign: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1200,
        width: "90%",
        ["@media (min-width:992px)"]: {
            width: "40%",
            paddingBottom: "5rem"
        },
        '&:focus-visible': {
            outline: "none"
        },
        color: "#fff",
        bgcolor: '#212121',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        paddingBottom: "3rem"
    };

    const locationModalStyle = {
        position: 'absolute',
        textAlign: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        borderRadius: 2,
        ["@media (min-width:992px)"]: {
            width: "40%",
            // paddingBottom: "5rem"
        },
        '&:focus-visible': {
            outline: "none"
        },
        color: "#fff",
        bgcolor: '#212121',
        border: '2px solid #000',
        boxShadow: 24,
        padding: "10px 10px 20px 30px"
    };

    // Pricing Plan States
    const [finalpricingPlans, setFinalPricingPlans] = useState(AdvancedPlans);
    const [priceList, setPriceList] = useState(2);
    // const [pricingPlans, setPricingPlans] = useState(plans);
    const [pricingLoading, setPricingLoading] = useState(false);
    const [pricingPlanErr, setPricingPlanErr] = useState(null);
    const [paymentAllowed, setPaymentAllowed] = useState(false);
    const [quantity, setQuantity] = useState(1);

    // Payment Link States
    const [btnStatus, setBtnStatus] = useState(false);
    const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [order, setOrder] = useState(null);
    const [notes, setNotes] = useState(null);
    const [introAllowed, setIntroAllowed] = useState(false);

    // Coupons States
    const [couponCodeInputValue, setCouponCodeInputValue] = useState(null);
    const [appliedCouponCode, setAppliedCouponCode] = useState(null);
    const [couponModal, setCouponModal] = useState(false);
    const [couponLoading, setCouponLoading] = useState(false);
    const [validCoupon, setvalidCoupon] = useState(null);
    const [discountDetails, setDiscountDetails] = useState(null);

    // Auth States
    const [skipRefresh, setSkipRefresh] = useState(true)
    const [skipMe, setSkipMe] = useState(true)

    // Payment Callback Status
    const [paymentCallbackLoading, setPaymentCallbackLoading] = useState(false);

    // Popups
    const [cityModal, setCityModal] = useState(false);
    const [city, setCity] = useState(null)
    const [pincode, setPincode] = useState("")

    // Modal
    const [comingSoon, setComingSoon] = useState(false);
    const [switchWarning, setSwitchWarning] = useState(false);
    const [renewWarning, setRenewWarning] = useState(false);

    // State States
    const [isStateAvailable, setIsStateAvailable] = useState(false);
    const [addressModal, setAddressModal] = useState(false);
    const [state, setState] = useState("");
    const [addressLoading, setAddressLoading] = useState(false);

    // Waitlist
    const [waitList, setWaitList] = useState(false);

    const notesRef = useRef();
    notesRef.current = notes;

    const subscriptionIdRef = useRef();
    subscriptionIdRef.current = order;

    // Auth States
    const { loggedIn, userInfo, userToken, userRefreshToken } = useSelector(state => state.auth);
    const user = useSelector(state => state.user.userDetails);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const fetchRefresh = () => {
        return fetch('https://api.antcloud.co/api/users/refresh',
            {
                method: 'GET',
                headers: {
                    'refresh': `JWT ${userRefreshToken}`
                }
            }
        ).then((res) => res.json())
            .then(res => {
                return res
            })
    }

    const fetchMe = async (meToken) => {
        try {
            const userData = await fetch('https://api.antcloud.co/api/users/me',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `JWT ${meToken}`
                    }
                }
            )
            const user = await userData.json();
            return user
        } catch (err) {
            console.log(err)
            toast.error(err)
        }
    }

    const handleCouponModalOpen = () => setCouponModal(true);
    const handleCouponModalClose = () => {
        setCouponModal(false);
        setvalidCoupon(null);
        setCouponCodeInputValue(null);
    };

    const checkType = (planName, userPlan) => {
        if (priceList == 1 && planName !== "TopUp") planName = `GTX${planName}`
        const currentPlan = userPlan.toLowerCase();
        const targetPlan = planName.toLowerCase();
        // Define plan equivalence and hierarchy
        const equivalentPlans = {
            "premium": ["premium"],
            "gtxpremium": ["gtxpremium"],
            "ultimate": ["premium", "ultimate"],
            "gtxultimate": ["gtxpremium", "gtxultimate"]
        };

        // Check for equivalence
        if (equivalentPlans[currentPlan]?.includes(targetPlan)) {
            return true;
        }
        return false;
    }

    const handlePricingCardClick = (cardId, planNameRef) => {
        if (loggedIn) {
            if (!isStateAvailable) setAddressModal(true)
            else {
                if (paymentAllowed) {
                    if (userInfo) {
                        if (planNameRef.textContent !== "TopUp") {
                            setSelectedCard(cardId)
                            if ((userInfo.currentPlan.toLowerCase().includes(('gtx')) && priceList == 2) || ((userInfo.currentPlan.toLowerCase() === "ultimate" || userInfo.currentPlan.toLowerCase() === "premium") && priceList == 1)) setSwitchWarning(true)
                            else if (checkType(planNameRef.textContent, userInfo.currentPlan)) {
                                setRenewWarning(true)
                            } else setBtnStatus(true);
                            // else createPaymentLink(planNameRef.textContent)
                        } else if (userInfo.currentPlan === "Basic" && cardId == 2) toast.error("You can't buy top-up plans!")
                        else {
                            setSelectedCard(cardId);
                            setBtnStatus(true);
                            // createPaymentLink(planNameRef.textContent)
                        }
                    }
                } else {
                    setComingSoon(true)
                }
            }

        }
    };

    // useEffect(() => {
    //     setPricingLoading(true)
    //     const fetchPricing = fetch('https://api.antcloud.co/api/billingPlans?sort=order',
    //         {

    //         }).then((response) => response.json())
    //         .then((plans) => {
    //             setPricingPlanErr(null)
    //             // if (userToken) {
    //             //     fetch('https://api.antcloud.co/api/users/me', {
    //             //         method: "GET",
    //             //         headers: {
    //             //             'Authorization': `JWT ${userToken}`
    //             //         }
    //             //     }).then(async res => {
    //             //         setPricingLoading(false);
    //             //         const userData = await res.json();
    //             //         if (userData.message && userData.message === "Invalid Token") {
    //             //             dispatch(logout());
    //             //         } else if (userData.message === "Token Expired") {
    //             //             fetch('https://api.antcloud.co/api/users/refresh',
    //             //                 {
    //             //                     headers: {
    //             //                         "refresh": `JWT ${userRefreshToken}`
    //             //                     }
    //             //                 }).then((resp) => resp.json()).then(async data => {
    //             //                     if (data.message.includes("Forbidden Request!") || data.message.includes("Re-authentication needed!")) {
    //             //                         toast.error('Session Expired! Please Login again.')
    //             //                         dispatch(logout());
    //             //                         navigate("/signin");
    //             //                     } else if (data.message.includes("New Access Pairs Generated")) {
    //             //                         dispatch(refreshTokens({ accessToken: data.accessToken, refreshToken: data.refreshToken }));
    //             //                         await fetchPricing;
    //             //                     }
    //             //                 })
    //             //         } else {
    //             //             const user = userData.user;
    //             //             plans.docs.forEach((obj, index) => {
    //             //                 console.log(user.currentPlan)
    //             //                 console.log(obj.planName)
    //             //                 console.log(user.currentPlan === "Basic" && obj.planName === "TopUp")
    //             //                 return obj.disabled = user.currentPlan === "Basic" && obj.planName === "TopUp" ? true : user.currentPlan !== "Basic" && obj.planName !== "TopUp" ? true : false;
    //             //             });
    //             //             console.log(plans.docs)
    //             //             setPricingPlans(plans.docs)
    //             //         }
    //             //     })
    //             // } 
    //             // else {
    //             setPricingLoading(false);
    //             setPricingPlans(plans.docs)
    //             // }
    //         }).catch((err) => {
    //             console.log(err)
    //             setPricingLoading(false);
    //             setPricingPlanErr('Something went wrong!')
    //         })
    // }, []);

    const createPaymentLink = (userToken) => {
        let planName;
        if (selectedCard === 0) planName = "Premium"
        else if (selectedCard === 1) planName = "Ultimate"
        else planName = "TopUp"
        // let planName = planName.textContent;
        if (priceList == 1 && planName !== "TopUp") planName = `GTX${planName}`

        if (loggedIn) {
            setBtnStatus(false)
            setPaymentLinkLoading(true)
            // fetch("http://localhost:8000/api/order/create", {
            fetch("https://api.antcloud.co/api/order/create", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + userToken,
                },
                body: JSON.stringify({
                    planName: planName,
                    platform: "Browser",
                    quantity: quantity,
                    couponCode: appliedCouponCode
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response && response.message) {
                        if (response.message.includes("Token Expired")) {
                            // refreshUserToken()
                            fetchRefresh().then(refreshData => {
                                if (refreshData.message === 'Re-authentication needed!' || refreshData.message === 'Forbidden Request!' || refreshData.message === 'Refresh Token Expired') {
                                    toast.error('Session Expired! Please Sign in again.')
                                    navigate('/signin');
                                    dispatch(logout())
                                } else if (refreshData.message === "New Access Pairs Generated!") {
                                    dispatch(refreshTokens(refreshData));
                                    createPaymentLink(refreshData.accessToken)
                                }
                            })
                        } else if (response.message.includes("Invalid Token")) {
                            toast.error("Session Expired! Please login again.")
                            navigate('/signin');
                            dispatch(logout())
                        }
                        toast.error(response.message);
                        setSelectedCard(null)
                        // setPlanError(response.message)
                    } else {
                        setBtnStatus(true);
                        setOrder(response)
                        setNotes(response.notes);
                        handlePayment(response);
                        // setPaymentLink(response.paymentLink);
                    }
                    setPaymentLinkLoading(false)
                })
                .catch((err) => {
                    setPaymentLinkLoading(false)
                    toast.error('Something went wrong!')
                    console.log(err)
                });
        } else {
            setBtnStatus(true);
            // setPaymentLink("/signin");
            console.log("API token not found");
        }
    };

    const handleCouponChange = (e) => {
        setCouponCodeInputValue(e.target.value)
        setvalidCoupon(null)
    }

    const handleValidateCouponCode = () => {
        setCouponLoading(true)
        fetch(`https://api.antcloud.co/api/coupons/check?code=${couponCodeInputValue}`)
            .then(async (res) => {
                const response = await res.json();
                console.log(response)
                setvalidCoupon(response.active)
                if (response.active) {
                    setAppliedCouponCode(couponCodeInputValue)
                    setDiscountDetails({ type: response.type, amount: response.discountAmount })
                    setTimeout(() => {
                        setCouponModal(false);
                    }, 1000)
                }
                setCouponLoading(false)
            }).catch((err) => {
                console.log(err)
                setvalidCoupon(false)
                setCouponLoading(false)
            })
    }

    const handleClose = () => {
        setBtnStatus(false);
        setComingSoon(false);
        setWaitList(false);
        setSelectedCard(null);
        // setCityModal(false);
    };

    const handleSwitchWarning = () => {
        setSwitchWarning(false);
        setSelectedCard(null);
    };

    const handleRenewWarning = () => {
        setRenewWarning(false);
        setSelectedCard(null);
    };

    const handleClusterSwitchYes = () => {
        createPaymentLink(userToken);
        setSwitchWarning(false);
        setRenewWarning(false);
    }

    const checkTransaction = (response) => {
        setPaymentCallbackLoading(true)
        // fetch("http://localhost:8000/api/subscriptions/verifyCallback",
        fetch("https://api.antcloud.co/api/subscriptions/verifyCallback",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({
                    payId: response.razorpay_payment_id,
                    orderId: subscriptionIdRef.current.id,
                    sign: response.razorpay_signature
                })
            }
        )
            .then(result => result.json())
            .then((result) => {
                if (result.result !== "Failed") {
                    if (notesRef.current.planName.toLowerCase() === "topup") {
                        setPaymentCallbackLoading(false)
                        toast.success("Payment Done!")
                        navigate('/stream')
                    } else {
                        setTimeout(() => {
                            if (!notesRef.current.addToUpcoming && !notesRef.current.switchCluster && !notesRef.current.upgrade && !notesRef.current.renew) {
                                localStorage.setItem('newAccount', "true");
                                const currentTime = new Date().getTime();
                                localStorage.setItem("timer", currentTime.toString());
                            }
                            setPaymentCallbackLoading(false)
                            toast.success("Payment Done!")
                            navigate('/stream')
                        }, 5000)
                    }
                } else {
                    setPaymentCallbackLoading(false)
                    toast.error("Payment Failed!")
                }
            }).catch(err => {
                setPaymentCallbackLoading(false)
                toast.error("Payment Failed!")
            })
    };

    const handlePayment = useCallback(async (order) => {
        const amount = order.amount * quantity;
        const options = {
            key: process.env.RAZORPAY_KEY_ID,
            // key: "rzp_test_gSzx0DgfSNdO3n",
            order_id: order.id,
            amount: amount,
            currency: "INR",
            name: "Antcloud",
            description: `Subscribe for AntCloud Plan`,
            image: "https://antcloud.co/static/media/Ant-Logo.8bee7434f594d1115fc3.webp",
            handler: (res) => checkTransaction(res),
            prefill: {
                name: order.notes.fullName,
                email: order.notes.email,
                contact: order.notes.phone,
            },
            notes: order.notes,
            theme: {
                color: "#22B9A6",
            },
        };

        const rzpay = new Razorpay(options);
        rzpay.on("payment.failed", function (response) {
            toast.error("Payment Failed!")
        });
        rzpay.open();
    }, [Razorpay]);

    const handleStateChange = (e) => {
        setState(e.target.value)
    }

    const submitLocation = () => {
        if (state === "") {
            toast.error("Please select your state.")
        } else if (pincode.length < 6) {
            toast.error("Please enter a valid 6 digit pincode.")
        } else {
            setAddressLoading(true);
            axios.patch(`https://api.antcloud.co/api/users/${userInfo.id}`,
                {
                    location: {
                        Pincode: pincode,
                        State: state
                    }
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${userToken}`,
                    }
                }
            )
                .then(response => {
                    setAddressModal(false)
                    setIsStateAvailable(true)
                    setAddressLoading(false);
                    // localStorage.removeItem("userInfoWithLocation")
                    toast.success("Location updated successfully!");
                })
                .catch(error => {
                    toast.error("Location updated successfully!");
                });
        }
    }

    // useEffect(() => {
    //     if (userData) {
    //         dispatch(setCredentials(userData.user))
    //         const userPayload = {
    //             id: userData.user.id,
    //             firstName: userData.user.firstName,
    //             currentPlan: userData.user.currentPlan,
    //             emailVerified: userData.user.emailVerified,
    //         }
    //         dispatch(setUserPlan(userPayload));
    //     } else if (userError) {
    //         const { message } = userError.data;
    //         if (message.includes("Token Expired")) {
    //             setSkipRefresh(false);
    //         } else if (message.includes("Invalid Token")) {
    //             dispatch(logout())
    //         }
    //     }
    // }, [userData, userError, dispatch]);

    // useEffect(() => {
    //     if (refreshData) {
    //       dispatch(refreshTokens(refreshData))
    //       getUserDetails()
    //     }
    //     else if (refreshError) {
    //       console.log(refreshError)
    //       toast.error('Session Expired! Please Sign in again.')
    //       navigate('/signin');
    //       dispatch(logout())
    //     }
    //   }, [refreshData, refreshError])

    // useEffect(() => {
    //     const query = location.search;
    //     if (location.pathname === "/pricing" && query.includes("signup=true")) {
    //         setWaitList(true)
    //     } else setWaitList(false)
    // }, [])

    // Commented for now
    // const { data: userData, error: userError, refetch: getUserDetails } = useGetUserDetailsQuery('userDetails', { skip: skipMe });
    // const { data: refreshData, error: refreshError, refetch: refreshUserToken } = useRefreshUserTokenQuery('refreshTokens', { skip: skipRefresh });

    // useEffect(() => {
    //     if (loggedIn) {
    //         setSkipMe(false);
    //         fetch('https://api.antcloud.co/api/globals/payment').then((res) => {
    //             const resData = res.json()
    //             return resData
    //         }).then((res) => {
    //             if (res.active) setPaymentAllowed(true)
    //             else setPaymentAllowed(false)
    //         }).catch((err) => {
    //             console.log(err)
    //         })
    //     }

    // }, []);

    useEffect(() => {
        if (loggedIn) {
            const tryMe = (meToken, refreshToken) => {
                fetchMe(meToken, refreshToken)
                    .then(userData => {
                        if (userData.message) {
                            if (userData.message === 'Token Expired') {
                                fetchRefresh().then(refreshData => {
                                    if (refreshData.message === 'Re-authentication needed!' || refreshData.message === 'Forbidden Request!' || refreshData.message === 'Refresh Token Expired') {
                                        toast.error('Session Expired! Please Sign in again.')
                                        navigate('/signin');
                                        dispatch(logout())
                                    } else if (refreshData.message === "New Access Pairs Generated!") {
                                        dispatch(refreshTokens(refreshData));
                                        tryMe(refreshData.accessToken, refreshData.refreshToken);
                                    }
                                })
                            } else if (userData.message === 'Invalid Token') {
                                toast.error('Session Expired! Please Sign in again.')
                                navigate('/signin');
                                dispatch(logout())
                            }
                        } else {
                            fetch('https://api.antcloud.co/api/globals/payment')
                                .then((res) => {
                                    const resData = res.json()
                                    return resData
                                }).then((res) => {
                                    if (res.active) {
                                        setPaymentAllowed(true)
                                        if (userData.user.currentPlan.toLowerCase() === 'basic' && (!userData.user.subscriptionStatus || userData.user.subscriptionStatus.toLowerCase() === 'cancelled')) setIntroAllowed(false)
                                        else setIntroAllowed(true)
                                    } else {
                                        if (userData.user.currentPlan.toLowerCase() === 'basic' && (!userData.user.subscriptionStatus || userData.user.subscriptionStatus.toLowerCase() === 'cancelled')) setPaymentAllowed(false)
                                        else {
                                            setPaymentAllowed(true)
                                            setIntroAllowed(true)
                                        }
                                    }
                                }).catch((err) => {
                                    console.log(err)
                                })

                            if (!userData.user.location || (userData.user?.location && !userData.user?.location?.State)) setAddressModal(true);
                            else setIsStateAvailable(true)

                            dispatch(setCredentials(userData.user))

                            const userPayload = {
                                id: userData.user.id,
                                firstName: userData.user.firstName,
                                currentPlan: userData.user.currentPlan,
                                emailVerified: userData.user.emailVerified,
                                email: userData.user.email
                            }
                            dispatch(setUserPlan(userPayload));
                        }
                    }).catch(err => {
                        console.log(err)
                    })
            }
            tryMe(userToken);
            setSkipMe(false);
        }
    }, []);

    // useEffect(() => {
    //     if (userData) {
    //         dispatch(setCredentials(userData.user))
    //         const userPayload = {
    //             id: userData.user.id,
    //             firstName: userData.user.firstName,
    //             currentPlan: userData.user.currentPlan,
    //             emailVerified: userData.user.emailVerified,
    //         }
    //         dispatch(setUserPlan(userPayload));
    //     } else if (userError) {
    //         const { message } = userError.data;
    //         if (message.includes("Token Expired")) {
    //             setSkipRefresh(false);
    //         } else if (message.includes("Invalid Token")) {
    //             dispatch(logout());
    //             toast.error("Session Expired. Please Login Again");
    //             navigate('/signin');
    //         }
    //     }
    // }, [userData, userError, dispatch]);

    // useEffect(() => {
    //     if (refreshData) {
    //         dispatch(refreshTokens(refreshData))
    //         getUserDetails()
    //     }
    //     else if (refreshError) {
    //         toast.error('Session Expired! Please Sign in again.')
    //         navigate('/signin');
    //         dispatch(logout())
    //     }
    // }, [refreshData, refreshError])

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     if (city === "") toast.error("City Field can't be empty.")
    //     else if (pincode.length !== 6) toast.error("Please input 6 digit pincode.")
    //     else {
    //         fetch(`https://api.antcloud.co/api/users/${userInfo.id}`,
    //             {
    //                 method: "PATCH",
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `JWT ${userToken}`
    //                 },
    //                 body: JSON.stringify({
    //                     location: {
    //                         City: city,
    //                         Pincode: pincode
    //                     }
    //                 })
    //             }
    //         )
    //         .then(res => res.json())
    //         .then(res => {
    //             let userData = {...userInfo};
    //             userData.location = {};
    //             userData.location.City = city;
    //             userData.location.Pincode = pincode;
    //             setUserPlan(userData)
    //             toast.success('Location Updated Successfully! You can now buy your plan.')
    //             setCityModal(false)
    //         })
    //         .catch(err => {
    //             console.log(err)
    //             toast.error('Something went wrong! Please try again later.')
    //         })
    //     }
    // }

    const handleWaitlist = async () => {
        let waitListLoading = true;
        const toastId = toast.loading("Loading")
        // setTimeout(() => {
        //     toast.dismiss(toastId)
        // },5000)
        // console.log(userInfo)
        try {
            const check =
                await axios.post('https://api.antcloud.co/api/waitlist', {
                    email: userInfo.email,
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `JWT ${userToken}`
                        }
                    })

            if (check) {
                console.log(check)
                toast.dismiss(toastId)
                toast.success("You're added to our waitlist successfully!");
            }
        } catch (err) {
            console.log(err)
            toast.dismiss(toastId)
            toast.error("Something Went Wrong! Try again later.")
        }
    }

    return (
        <>

            {/* Processing Payments Model */}
            <Modal
                open={paymentCallbackLoading}
                // onClose={handleClose}
                className='ComingSoon-modal'
            >
                <Box sx={loaderModalStyle}>

                    <p>Processing Payment.</p>
                    <p>Please do not leave this page!</p>
                    <Spinner topPosition={60} width={window.innerWidth < 768 ? 16 : 10} />
                </Box>
            </Modal>

            {/* WaitList Modal */}
            <Modal
                open={waitList}
                onClose={handleClose}
                className='ComingSoon-modal'
            >
                <Box sx={style}>
                    {/* <CancelIcon sx={{ textAlign: "right" }} /> */}
                    <Typography sx={{
                        textAlign: "left", ["@media (max-width:992px)"]: {
                            fontSize: "0.9rem"
                        },
                    }} variant="h5">
                        Hey there,
                        <br />
                        <br />
                        We're absolutely thrilled to have you join Antcloud waitlist!
                        <br />
                        <br />
                        We're not accepting payments at the moment, but don't worry – you're at the front of the line for when we open up again.
                        <br />
                        <br />
                        Regards,
                        <br />
                        Antcloud Team
                    </Typography>
                </Box>
            </Modal>

            {/* Not Accepting Any New Members Modal */}
            <Modal
                open={comingSoon}
                // open={true}
                onClose={handleClose}
                className='ComingSoon-modal'
                sx={{ '&:focus-visible': { outline: "none" } }}
            >
                <Box sx={style}>
                    <Typography sx={{
                        textAlign: "center", ["@media (max-width:992px)"]: {
                            fontSize: "0.9rem"
                        },
                    }} variant="h5">
                        Sorry! We're currently not accepting any new members.
                        <br />
                        <button onClick={handleWaitlist} style={{ fontSize: "18px" }} className="btn-comm btnNext mt-4 mb-4">Join Waitlist</button>

                        <br />
                        {/* Please follow our social media handles for updates. */}
                        <Typography sx={{
                            textAlign: "center",
                            fontSize: "1.3rem",
                            ["@media (max-width:992px)"]: {
                                fontSize: "0.9rem"
                            },
                        }} variant="h5">
                            Please click the above button to join our waitlist.
                        </Typography>
                    </Typography>
                </Box>
            </Modal>

            {/* Switch Cluster Warning */}
            <Modal
                open={switchWarning}
                onClose={handleSwitchWarning}
                className='ComingSoon-modal'
            >
                <Box sx={style}>
                    <Typography sx={{
                        textAlign: "left",
                        ["@media (max-width:992px)"]: {
                            fontSize: "0.9rem"
                        },
                    }} variant="h6">
                        NOTE : Purchasing this plan means your data will not transfer to your new PC. Be cautious as all information will not be accessible once you migrate to a different PC.
                        {/* You're about to switch from an {priceList == 1 ? 'Advanced' : 'Intro'} to an {priceList == 1 ? 'Intro' : 'Advanced'} Plan.
                        <br />
                        Your current data will not be carry forwarded to your new plan.
                        <br />
                        <br /> */}
                        <br />
                        <br />
                        <h4 style={{ textAlign: "center" }}>Do you want to continue?</h4>
                    </Typography>
                    <div style={{ display: "flex", marginTop: "2rem", width: "100%", justifyContent: "space-around" }}>
                        <button className="btn-comm btn-lg-2 btnNext mt-2" onClick={handleClusterSwitchYes}> Yes </button>
                        <button className="btn-comm btn-lg-2 btnNext mt-2 switchConfirmationButton" style={{ backgroundColor: "#E4606D", borderColor: "#E4606D" }} onClick={handleSwitchWarning}> No </button>
                        {/* <Button variant="contained" color="success" onClick={handleClusterSwitchYes} >Yes</Button> */}
                        {/* <Button variant="contained" onClick={handleSwitchWarning} >No</Button> */}
                    </div>
                </Box>
            </Modal>

            {/* Upcomg Plan Warning */}
            <Modal
                open={renewWarning}
                onClose={handleRenewWarning}
                className='ComingSoon-modal'
            >
                <Box sx={style}>
                    <Typography sx={{
                        textAlign: "left",
                        ["@media (max-width:992px)"]: {
                            fontSize: "0.9rem"
                        },
                    }} variant="h6">
                        Warning: Your current subscription period isn't finished yet. If you purchase this plan, it will get activated once your current period finishes. For more hours, we recommend a Top up plan.
                        <br />
                        <br />
                        <h4 style={{ textAlign: "center" }}>Do you want to continue?</h4>
                    </Typography>
                    <div style={{ display: "flex", marginTop: "2rem", width: "100%", justifyContent: "space-around" }}>
                        <button className="btn-comm btn-lg-2 btnNext mt-2" onClick={handleClusterSwitchYes}> Yes </button>
                        <button className="btn-comm btn-lg-2 btnNext mt-2 switchConfirmationButton" style={{ backgroundColor: "#E4606D", borderColor: "#E4606D" }} onClick={handleRenewWarning}> No </button>
                        {/* <Button variant="contained" color="success" onClick={handleClusterSwitchYes} >Yes</Button> */}
                        {/* <Button variant="contained" onClick={handleSwitchWarning} >No</Button> */}
                    </div>
                </Box>
            </Modal>

            {/* City Modal */}
            {/* <Modal
                open={cityModal}
                onClose={handleClose}
                className='ComingSoon-modal'
            >
                <Box sx={style}>
                    <Typography sx={{
                        textAlign: "center", ["@media (max-width:992px)"]: {
                            fontSize: "0.9rem"
                        },
                    }} variant="h5">
                        <h5>Please Complete your profile!</h5>
                        <div className="user-auth-form-box custom-form-box">
                            <form onSubmit={handleSubmit}>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="City"
                                    required
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                                <input
                                    type="text"
                                    pattern="[0-9]*"
                                    maxLength="6"
                                    className="form-control"
                                    placeholder="Pincode"
                                    required
                                    value={pincode}
                                    onChange={(e) => {
                                        setPincode(e.target.value.replace(/\D/g, ""))
                                    }}
                                />
                                <button className="btn-comm btn-lg" type="submit">Save</button>
                            </form>
                        </div>
                    </Typography>
                </Box>
            </Modal> */}

            {/* State Modal */}
            <Modal
                open={addressModal}
                onClose={() => setAddressModal(false)}
                className='ComingSoon-modal'
            >
                <Box sx={locationModalStyle}>
                    {addressLoading
                        ? <Spinner />
                        : <>
                            <div style={{ display: "flex", marginBottom: "10px", justifyContent: "end" }}><CloseIcon style={{ cursor: "pointer" }} onClick={() => setAddressModal(false)} /></div>
                            <div style={{ paddingRight: "20px" }}>
                                <h2>Please update your location</h2>
                                <p>For invoicing purposes, we need your accurate location.</p>

                                <FormControl className="mt-2" style={{ borderRadius: "20px !important" }} fullWidth>
                                    <InputLabel id="demo-simple-select-label">{`${state ? "" : "Select state"}`}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={state}
                                        onChange={handleStateChange}
                                    >
                                        {statesOfIndia.map((item) => (
                                            <MenuItem value={item.value}>{item.value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <input
                                    onChange={(e) => {
                                        setPincode(() => {
                                            const a = e.target.value.replace(/\D/g, "");
                                            return a;
                                        })
                                    }}
                                    style={{ height: "55px", borderRadius: "20px", marginBottom: "20px", marginTop: "30px" }}
                                    className="form-control"
                                    placeholder="Pin code"
                                />
                                <button onClick={submitLocation} className="btn-comm btn-lg-2 btnNext mt-2">
                                    Submit
                                </button>
                            </div>
                        </>}
                </Box>
            </Modal>

            {/* Banner Sec */}
            <section className="inner-banner-sec pricing-banner-sec" >
                <div className="" data-aos="fade-up" data-aos-delay="300">
                    <div className="container custom-container" >
                        <div className="banner-caption text-center">
                            <h1 className="main-heading main-heading-md">Pricing</h1>
                            <p className="subHeading-text">Check out our monthly subscription plans along with our top-up plan in case you run out of hours!</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Pricing Table Sec */}
            <section className="pricingN-sec" style={{ position: "relative" }}>
                <p style={{ textAlign: "center", color: "red" }}>  {pricingPlanErr ? pricingPlanErr : ''}</p>
                <div style={{ display: "flex", justifyContent: "center", width: "100%", padding: "0px 10px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer", maxWidth: "600px", width: "100%", borderRadius: "10px", background: "transparent", border: "1px solid", marginBottom: "40px" }}>
                        <div onClick={() => { setPriceList(1); setFinalPricingPlans(IntroPlans); setSelectedCard(null); setBtnStatus(false) }} style={priceList === 1 ? { background: "#54B4A3", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", width: "50%", textAlign: "center", padding: "20px 0", } : { width: "50%", textAlign: "center", padding: "20px 0", }}>
                            Intro Plans
                        </div>
                        <div onClick={() => { setPriceList(2); setFinalPricingPlans(AdvancedPlans); setSelectedCard(null); setBtnStatus(false) }} style={priceList === 2 ? { background: "#54B4A3", width: "50%", borderTopRightRadius: "10px", borderBottomRightRadius: "10px", textAlign: "center", padding: "20px 0", borderLeft: "1px solid" } : { width: "50%", textAlign: "center", padding: "20px 0", borderLeft: "1px solid" }}>
                            Advanced Plans
                        </div>
                    </div>
                </div>
                <p style={{ textAlign: "center", paddingTop: "-1rem", paddingBottom: "1rem" }}>
                    Select a plan to continue
                </p>
                {loggedIn && !introAllowed && priceList === 1 && <div style={{ position: "absolute", userSelect: "none", top: "50%", left: "40%", right: "50%", padding: "1rem 2rem 0", textAlign: "center", backgroundColor: "#212121", border: "1px solid white", height: "7rem", width: "25rem", bottom: 0 }}>
                   {/* Our apologies but currently all our Intro-plans slots are full. */}
                   Note: Sorry! The Intro plan slots are currently full. <br/> You can purchase the Advanced plans.
                </div>}
                <div className={` ${loggedIn && !introAllowed && priceList === 1 ? "blurred container custom-container" : "container custom-container"} `}>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="pricingN-responsive">
                                <div id="pricing-row" style={{ position: "relative" }} className="pricingN-row">
                                    <div className="pricingN-cols pricingN-cols-lg">
                                        <ul>
                                            <li className="pricingN-heading">
                                                &nbsp;
                                            </li>
                                            <li className="pricingN-subHeading">Pricing</li>
                                            <li>Stream Time</li>
                                            <li>Resolution</li>
                                            <li>RAM/VRAM</li>
                                            <li>Ray Tracing</li>
                                            <li>Validity</li>
                                        </ul>
                                    </div>
                                    {pricingLoading ? <Spinner /> : <></>}
                                    {/* {pricingPlans.map((plan, index) => { */}
                                    {finalpricingPlans.map((plan, index) => {
                                        if (plan.planName === "Basic") return;
                                        return (
                                            <PricingCards
                                                key={index}
                                                isSelected={selectedCard === index}
                                                onClick={priceList === 1 && !introAllowed ? () => { } : handlePricingCardClick}
                                                resolution={plan.resolution}
                                                planName={plan.planName}
                                                planCode={plan.planCode}
                                                planPrice={plan.planPrice}
                                                discountDetails={discountDetails}
                                                planHourLimit={plan.planHourLimit}
                                                planTerm={plan.planTerm}
                                                index={index}
                                                disabled={plan.disabled}
                                                setQuantity={setQuantity}
                                                quantity={quantity}
                                            />
                                        )
                                    })}
                                </div>
                            </div>

                            {/* For Coupon Uncomment */}
                            {
                                loggedIn
                                    ? <div style={{ marginTop: "2.5rem", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                        {appliedCouponCode ? <p style={{ marginBottom: "0.5rem" }}> <span style={{ color: "#45d4b8" }}> {appliedCouponCode}  </span> Applied! </p> : <></>}
                                        <button onClick={handleCouponModalOpen} style={{ backgroundColor: "transparent", color: "#45d4b8", textDecoration: "underline" }}>Have a Coupon?</button>
                                    </div>
                                    : <></>
                            }

                            <div className={`pricingN-tnc text-center ${!loggedIn || btnStatus ? 'btnNextActive' : ''}`}>
                                <p style={{ textAlign: "left" }}>
                                    Note: 
                                    {/* Note: The top-up plan is only applicable if you are already on a monthly plan */}
                                    <ol type="1">
                                        <li style={{ listStyleType: "unset" }}> &nbsp; All Plans are non-refundable.</li>
                                        <li style={{ listStyleType: "unset" }}> &nbsp; 250 GBs of Storage is provided on all Virtual PCs</li>
                                        <li style={{ listStyleType: "unset" }}> &nbsp; Switching from Intro to Advanced Tier, or vice versa, means your data will not transfer to your new PC. <br /> Be cautious as all information will not be accessible once you migrate to a different PC.</li>
                                    </ol>
                                </p>
                                {
                                    loggedIn
                                        ? <button style={paymentLinkLoading ? { position: "relative", height: "3rem" } : {}}
                                            // onClick={handlePayment}
                                            onClick={() => createPaymentLink(userToken)}
                                            className="btn-comm btn-lg-2 btnNext mt-2">{paymentLinkLoading ? <> <Spinner bottomPosition={1} /> </> : <>Next</>}</button>
                                        : <a href="/signin" rel="noreferrer norigin" className='btn-comm btn-lg-2 btnNext mt-2'>
                                            Sign In Now
                                        </a>
                                }
                                {/* <a onClick={() => setComingSoon(true)} target="_blank" href={paymentLink ? paymentLink : "/signin"} rel="noreferrer norigin" className='btn-comm btn-lg-2 btnNext mt-2'>{paymentLinkLoading ? <>Loading </> : <>Next</>}</a> */}
                                {/* <a href="#" className='btn-comm btn-lg-2 btnNext mt-2'>Next</a> */}
                            </div>
                        </div>
                    </div>

                </div>
            </section >
            {/* Latest News */}
            {/* < LatestNews /> */}

            <Modal
                open={couponModal}
                onClose={handleCouponModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="couponModalBox" sx={style}>
                    <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={handleCouponModalClose} />
                    <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
                        Validate Coupon Code
                    </Typography>
                    <FormControl sx={{ my: 3, width: "100%" }}>
                        <InputLabel
                            htmlFor="component-outlined"
                            sx={{
                                color: "#fff",
                                '&.Mui-focused': {
                                    color: "#fff"
                                }
                            }}>
                            Coupon Code
                        </InputLabel>
                        <OutlinedInput
                            onChange={handleCouponChange}
                            value={couponCodeInputValue}
                            sx={{
                                color: "#fff",
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#fff !important",
                                },
                                '&.Mui-focused': {
                                    borderColor: "#fff"
                                }
                            }}
                            id="component-outlined"
                            label="Coupon Code"
                        />
                    </FormControl>
                    {validCoupon ? <p style={{ color: "#4CBB17", textAlign: "center" }}> Coupon Applied Successfully! </p>
                        : validCoupon !== null || validCoupon === false ? <p style={{ color: "#f08080", textAlign: "center" }}> Invalid Coupon Code </p> : null}
                    <button onClick={handleValidateCouponCode} disabled={!couponCodeInputValue || (couponCodeInputValue && couponCodeInputValue.length < 3) || couponLoading}
                        style={{ width: "100%" }}
                        className='btn-comm btn-lg btn-block mt-2'
                    >
                        Submit
                    </button>
                </Box>
            </Modal>

        </>
    )
}